<template>
  <div>
    <Header :title="isEdit ? '修改活动专场' : '新增活动专场'" @back="onBack(false)"></Header>
    <a-spin :spinning="loading">
      <a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 6, xxl: 5}"
              :wrapperCol="{span: 14, xxl: 13 }" @finish="onSubmit">

        <a-form-item name="title" :rules="[{ required: true, message: '必填项不允许为空' }]" label="专场名称">
          <a-input v-model:value="modelRef.title" placeholder="请输入专场名称"></a-input>
        </a-form-item>

        <a-form-item name="sort" extra="优先级越高越靠前，不填则为0" label="优先级">
          <a-input v-model:value="modelRef.sort" placeholder="请输入优先级"></a-input>
        </a-form-item>

        <a-form-item label="专场商品" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-form-item-rest>
            <selectGoods v-model:value="goodsList" :selectItems="list" @change="(dataList) => {list = dataList}"></selectGoods>
          </a-form-item-rest>
          <div style="margin-top: 10px; width: 700px;" v-if="list.length">
            <a-table :pagination="false" :columns="columns" :dataSource="list" rowKey="id"
                     :scroll="{ x: 450 }">
              <template #bodyCell="{ column, record, index }">
                <template v-if="column.key === 'action'">
                  <a-button type="link" @click="onDelete(record, index)">删除</a-button>
                </template>
              </template>
            </a-table>
          </div>
        </a-form-item>

        <a-form-item label="专场封面图" :rules="[{required: true, message: '必填项不允许为空'}]" extra="建议图片比例：167 : 120">
          <a-upload
              v-model:file-list="coverFileList"
              list-type="picture-card"
              action="/admin/ajaxUpload.do"
              accept="image/*"
              @change="handleCoverChange"
          >
            <div v-if="coverFileList.length < 1">
              <Icon icon="PlusOutlined"></Icon>
              <div style="margin-top: 8px">Upload</div>
            </div>
          </a-upload>
        </a-form-item>

        <a-form-item label="是否在首页展示" name="isRecommend">
          <a-switch style="margin-right: 10px;" v-model:checked="modelRef.isRecommend" checked-children="是" un-checked-children="否" />
          <a-tooltip>
            <template #title>将按照优先级在商城首页专场位展示</template>
            <Icon icon="QuestionCircleOutlined"></Icon>
          </a-tooltip>
        </a-form-item>

        <a-form-item label="是否启用" name="isDisabled">
          <a-switch v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />
        </a-form-item>

        <a-row>
          <a-col :span="24" style="margin-top: 20px;text-align: center;">
            <a-button type="primary" html-type="submit">提交</a-button>
            <a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
          </a-col>
        </a-row>

      </a-form>

    </a-spin>
  </div>
</template>

<script>
import {
  Icon
} from '@/components/icon/icon.js';
import Header from '@/components/header/header.vue';
import selectGoods from '@/components/select/selectGoods/index.vue';
import {getAppSellDetail, saveAppSell, updateAppSell} from "../../../../service/modules/mall";
export default {
  components: {
    Header,
    Icon,
    selectGoods,
  },
  props: {
    id: {
      type: Number,
      default: 0
    },
    isEdit: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      coverFileList: [],
      loading: false,
      formState: {},
      modelRef: {
        isRecommend: false,
        isDisabled: true,
      },
      columns: [{
        title: '商品名称',
        dataIndex: 'mainTitle',
      }, {
        title: '商品编码',
        dataIndex: 'id'
      }, {
        title: '商品分类',
        dataIndex: 'classifyName',
      }, {
        title: '操作',
        key: 'action',
      }],
      goodsList: [],
      list: [],
    }
  },
  created() {
    if(this.isEdit) {
      this.getData();
    }
  },
  methods: {
    onBack(isRef) {
      this.$emit('back', isRef);
    },
    onDelete(item, index) {
      let i = this.goodsList.indexOf(item.id);
      this.goodsList.splice(i, 1);
      this.list.splice(index, 1);
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getAppSellDetail({id: this.id});
        this.loading = false;
        if(ret.code === 200) {
          ret.data.isDisabled = ret.data.isDisabled ? false : true;
          ret.data.isRecommend = ret.data.isRecommend ? true : false;
          if(ret.data.homeImgUrl){
            this.coverFileList = ret.data.homeImgUrl.split(',').map(item=>{
              return {
                url: item,
              }
            })
          }
          if(ret.data.goodsList) {
            ret.data.goodsList.forEach(item => {
              this.goodsList.push(item.id + '');
            })
          }
          this.list = ret.data.goodsList;
          delete ret.data.listImgUrl;
          delete ret.data.homeImgUrl;
          delete ret.data.goodsList;

          this.modelRef = ret.data;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    handleCoverChange(info) {
      if (info.file.status === 'done') {
        this.coverFileList = info.fileList;
        const $coverFileList = [...this.coverFileList];
        this.coverFileList = $coverFileList.map(file => {
          if (file.response) {
            file.url = file.response.data.imgUrl;
          }
          return file;
        });
      }
    },
    async onSubmit() {
      if(this.list.length === 0) {
        this.$message.error('专场卖品不能为空！');
        return;
      }
      if(this.coverFileList.length === 0) {
        this.$message.error('专场封面图不能为空！');
        return;
      }
      this.modelRef.goodsIdList = this.goodsList;

      let postData = JSON.parse(JSON.stringify(this.modelRef));

      postData.isDisabled = postData.isDisabled ? 0 : 1;
      postData.isRecommend = postData.isRecommend ? 1 : 0;
      const imgCoverList = [];
      this.coverFileList.forEach(item=>{
        imgCoverList.push(item.url);
      });
      postData.homeImgUrl = imgCoverList.join(',');

      this.loading = true;
      try {
        let ret;
        if(this.isEdit) {
          postData.id = this.id;
          ret = await updateAppSell(postData);
        } else {
          ret = await saveAppSell(postData);
        }
        this.loading = false;
        if(ret.code === 200) {
          this.$message.success('操作成功');
          this.onBack(true);
        }
      } catch(e) {
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>